/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import queryString from "query-string"
import "@fontsource-variable/inter"

export let onPreRouteUpdate = ({ location, prevLocation }) => {
  // prevent VIT dialog from staying on top of page between natigation
  let vitDialog = document.getElementById("_vit-dialog")
  if (!!vitDialog) {
    vitDialog.remove()
  }

  if (prevLocation === null) {
    return
  }

  if (prevLocation.search.includes("campaign=")) {
    // don't do anything if campaign param is already set for location
    if (queryString.parse(location.search).campaign) {
      return
    }

    // otherwise, append campaign param to location search
    let paramSymbol = location.search ? "&" : "?"
    location.search += `${paramSymbol}campaign=${
      queryString.parse(prevLocation.search).campaign
    }`
  }
}
